@import 'constants/styles.constants.module';

h3.secondTitle {
  font-weight: $fontWeightMedium;
  line-height: 4rem;
  margin-bottom: 3rem;
}

.cardsWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 70rem;

  @include max(xs) {
    justify-content: center;
  }
}

.cardsWrapperInner {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.successText {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin-top: 2rem;
  max-width: 48rem;
}

svg.icon {
  padding-bottom: 2rem;
  width: 11rem;
}
