@import 'constants/styles.constants.module';

h2.title {
  font-weight: $fontWeightMedium;
}

.text {
  font-weight: $fontWeightLight;
}

.signUpWrapper {
  max-width: 17rem;

  @include max(xs) {
    flex-basis: 100%;
    margin-top: 3rem;
    text-align: center;

    h2 {
      text-align: center;
    }
  }
}
