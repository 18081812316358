@import 'constants/styles.constants.module';

h2.title {
  font-weight: $fontWeightMedium;
}

.error {
  display: block;
  width: 100%;
  color: $colorWarning !important;
  font-weight: $fontWeightLight;
  text-align: left;
}

div.cardBadge {
  background: var(--colorMain);
}

div.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  min-height: 500px;
  padding: 6.25rem 10.625rem 3.25rem;
  box-shadow: none;

  @include max(m) {
    width: 30rem;
    padding: 6.25rem 4.625rem 3.25rem;
  }

  @include max(s) {
    width: 26rem;
    min-height: 410px;
    padding: 3.25rem 2.625rem 0;
  }

  @include max(xs) {
    width: unset;
    max-width: 30rem;
  }
}

.cardContent {
  width: 100%;
  text-align: center;
}

div.input {
  margin-top: 2rem;

  &:first-of-type {
    margin-top: 2rem;
  }

  svg {
    stroke: #3d60d34d;
    @include ie-only() {
      width: 1rem;
      height: 1rem;
      stroke: rgba(61, 96, 211, 0.3);
    }
  }
}

button.button {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
