@import 'constants/styles.constants.module';

.forgottenPassword {
  display: flex;
  justify-content: flex-end;

  a {
    font-size: $fontSizeSmallText;
    cursor: pointer;
  }
}
